import React from 'react';
import { Link } from 'gatsby';
import { SocialIcon } from 'react-social-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

function Footer(props) {

    return (
        <div className="container">
            <div className="footer">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ paddingTop: '20px'}}>
                        <Link to="/" style={{ textDecoration: 'inherit', color: 'inherit' }} >
                            <h1 className="header-mainpage-title non-selectable" style={{ color: 'grey', fontSize: '1.8em' }}>Bonded <FontAwesomeIcon icon={ faGlobeEurope } /> World</h1>
                        </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-md-8 col-lg-9">
                        <div className="m-4 m-md-0">
                            <div className="row">
                                <div className="col-auto footer-links"><Link to="/aboutus" style={{color: 'black' }}>About Us</Link></div>
                                <div className="col-auto footer-links"><Link to="/contactus" style={{ color: 'black' }}>Contact Us</Link></div>
                                <div className="col-auto footer-links"><Link to="/terms-conditions" style={{color: 'black' }}>Terms & Conditions</Link></div>
                                <div className="col-auto footer-links"><Link to="/privacy-policy" style={{color: 'black' }}>Privacy Policy</Link></div>
                            </div>

                            <div className="row">
                                <SocialIcon className="social-icon" url="https://instagram.com/bondedworld" target="_blank" rel="noopener noreferrer" />
                                <SocialIcon className="social-icon" url="https://www.youtube.com/channel/UCfu-eFk-tUuYZOwGhpf7qGQ/" target="_blank" rel="noopener noreferrer" />
                                <SocialIcon className="social-icon" url="https://www.facebook.com/bondedworld" target="_blank" rel="noopener noreferrer" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-left">
                    <div className="col-auto">
                        <p style={{ fontSize: '12px' }}>&copy; Copyright 2021 BondedWorld. All rights reserved. No part of this site may be reproduced without our written permission.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

// <SocialIcon className="social-icon" url="https://twitter.com/bondedworld" target="_blank" rel="noopener noreferrer" />

export default Footer;
